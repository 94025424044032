import React from "react"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import styled, { useTheme } from "styled-components"
import PropTypes from "prop-types"
import { Selector } from "../selector"
import { PricingHeading } from "../pricing-header"
import { PricingColumn } from "../pricing-column"
import { PM_PRICING_COMPARISON, PM_PRICING_ENTRIES_CUSTOM, PM_PRICING_ENTRIES_FREE, PM_PRICING_ENTRIES_STANDARD, PM_PRICING_HEADER_CUSTOM, PM_PRICING_HEADER_FREE, PM_PRICING_HEADER_STANDARD } from "./pricing-constants"
import media from "@styles/media"

const MobileWrapper = styled(Div)`
  ${media.md_up`
    display: none;
  `}
`

const PowerMetricsDesktop = ({
  monthly,
  handleClick,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  const { shadow } = useTheme()

  return (
    <Div
      background="white"
      className="pricing-main"
      hideOnMd
      borderRadius="1.5rem"
      boxShadow={shadow.twentyfour}
    >
      <Grid
        columns="repeat(4, 1fr)"
        columnsMd="1fr"
        borderRadius="1.5rem 1.5rem 0 0"
        zIndex={2}
      >
        <PricingHeading
          info={
            <Selector
              monthly={monthly}
              handleMonthlyClick={handleMonthlyClick}
              handleYearlyClick={handleYearlyClick}
            />
          }
        />
        <PricingHeading {...PM_PRICING_HEADER_FREE} monthly={monthly} />
        <PricingHeading {...PM_PRICING_HEADER_STANDARD} monthly={monthly} />
        <PricingHeading
          {...PM_PRICING_HEADER_CUSTOM}
          monthly={monthly}
          contact={true}
          handleYearlyClick={handleYearlyClick}
          s
        />
      </Grid>
      <Grid
        columns="repeat(4, 1fr)"
        background="white"
        columnsMd="1fr"
        borderRadius="0 0 1.5rem 1.5rem"
        overflow="hidden"
      >
        <PricingColumn first info list={PM_PRICING_COMPARISON} />
        <PricingColumn
          button
          list={PM_PRICING_ENTRIES_FREE}
          monthly={monthly}
        />
        <PricingColumn
          button
          list={PM_PRICING_ENTRIES_STANDARD}
          monthly={monthly}
        />
        <PricingColumn
          last
          list={PM_PRICING_ENTRIES_CUSTOM}
          monthly={monthly}
          isAvailableMonthly={false}
          handleYearlyClick={handleYearlyClick}
          onClick={handleClick}
        />
      </Grid>
    </Div>
  )
}

PowerMetricsDesktop.propTypes = {
  monthly: PropTypes.bool,
  handleClick: PropTypes.func,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}

const PowerMetricsMobile = ({
  monthly,
  handleClick,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  const { shadow } = useTheme()

  return (
    <MobileWrapper
      className="pricing-mobile"
      style={{ zIndex: 1, position: "relative" }}
    >
      <Selector
        monthly={monthly}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
        style={{ margin: "0 auto 2rem", zIndex: 1, position: "relative" }}
      />
      <Div
        borderRadius="1rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...PM_PRICING_HEADER_FREE} monthly={monthly} />
        <PricingColumn
          mobile
          button
          list={PM_PRICING_ENTRIES_FREE}
          titles={PM_PRICING_COMPARISON}
          monthly={monthly}
        />
      </Div>
      <Div
        borderRadius="1rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading {...PM_PRICING_HEADER_STANDARD} monthly={monthly} />
        <PricingColumn
          mobile
          button
          list={PM_PRICING_ENTRIES_STANDARD}
          titles={PM_PRICING_COMPARISON}
          monthly={monthly}
        />
      </Div>
      <Div
        borderRadius="1rem"
        overflow="hidden"
        boxShadow={shadow.soft}
        margin="0 0 2rem"
        background="white"
      >
        <PricingHeading
          {...PM_PRICING_HEADER_CUSTOM}
          monthly={monthly}
          isAvailableMonthly={false}
        />
        <PricingColumn
          mobile
          list={PM_PRICING_ENTRIES_CUSTOM}
          titles={PM_PRICING_COMPARISON}
          monthly={monthly}
          isAvailableMonthly={false}
          onClick={handleClick}
        />
      </Div>
    </MobileWrapper>
  )
}

PowerMetricsMobile.propTypes = {
  monthly: PropTypes.bool,
  handleClick: PropTypes.func,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}

export const PowerMetricsTable = ({
  handleClick,
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  return (
    <>
      <PowerMetricsDesktop
        monthly={monthly}
        handleClick={handleClick}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
      />
      <PowerMetricsMobile
        monthly={monthly}
        handleClick={handleClick}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
      />
    </>
  )
}

PowerMetricsTable.propTypes = {
  handleClick: PropTypes.func,
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
  microsite: PropTypes.bool,
}
