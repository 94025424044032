import Div from "@components/elements/div"
import styled, { css, keyframes } from "styled-components"
import media from "../../../../styles/media"
import theme from "../../../../styles/theme"

const scrollingAnim = keyframes`
  from {
    transform: translate3d(-3383px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

export const LogoAnimation = styled.div`
  width: 10107px;
  display: flex;
  gap: 50px;
  animation: ${scrollingAnim} 60s linear infinite;
`

export const HeroContainer = styled(Div)`
  background: rgba(255, 255, 255, 0.15);
  padding: 1rem;
  border-radius: 2rem;
  box-shadow: ${theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.59);
  width: 100%;
  ${media.sm`
    ${props =>
      props.reverse &&
      css`
        order: -1;
      `}
  `}
`
