import React from "react"
import PropTypes from "prop-types"

export const InformationIcon = ({ color, style }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5229 20 20 15.5228 20 10C20 4.47717 15.5229 0 10 0C4.47705 0 0 4.47717 0 10C0 15.5228 4.47705 20 10 20ZM9.1333 7.90955V15.2238H10.8572V7.90955H9.1333ZM9.63208 6.80847C9.74585 6.85046 9.86865 6.87146 10 6.87146C10.2761 6.87146 10.5112 6.78101 10.7048 6.59998C10.9016 6.41589 11 6.19519 11 5.93811C11 5.67773 10.9016 5.45715 10.7048 5.27625C10.5112 5.09204 10.2761 5 10 5C9.72705 5 9.49194 5.09204 9.29517 5.27625C9.09839 5.45715 9 5.67773 9 5.93811C9 6.19519 9.09839 6.41589 9.29517 6.59998C9.39722 6.69385 9.50952 6.76331 9.63208 6.80847Z"
          fill={color ?? "#B6B9C8"}
        />
      </g>
    </svg>
  )
}

InformationIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}
