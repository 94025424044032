import React, { useState } from "react"
import Modal from "@components/elements/modal"
import { FormWrapper } from "@components/styles/form-wrapper"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import PropTypes from "prop-types"
import Paragraph from "@components/elements/paragraph"

export const GetADemoForm = ({ modalOpen, setModalOpen }) => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <Modal show={modalOpen} setModalState={setModalOpen}>
      {submitted ? (
        <Paragraph>Thanks for contacting us, we look forward to talking with you soon.</Paragraph>
      ) : (
        <FormWrapper>
          <Form>
            <HubspotForm
              portalId="7477725"
              formId="41ddd3bb-1427-4742-83f9-000628bfe255"
              onSubmit={() => setSubmitted(true)}
            />
          </Form>
        </FormWrapper>
      )}
    </Modal>
  )
}

GetADemoForm.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
}
