import Div from "@components/elements/div"
import React from "react"
import Image from "@components/image"
import Heading from "@components/elements/heading"
import PropTypes from "prop-types"
import { KlipsFAQ } from "@components/faq/klips"
import styled, { useTheme } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import { KlipsAddOns } from "./add-ons"

const LogoWrapper = styled.div`
  svg {
    max-width: 700px;
  }
`

export const KlipsDetails = ({ handleClick }) => {
  const { color } = useTheme()
  const { data } = useStaticQuery(graphql`
    query KlipsTable {
      data: allDirectusFiles(
        filter: {
          directus_id: { in: ["01c7bbb0-277d-499b-be8a-46c14c00f95f"] }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)
  const images = processImages(data.edges)

  return (
    <Div background="white">
      <KlipsAddOns handleClick={handleClick} />
      <Div margin="12rem 0" marginMd="8rem 0" marginSm="6rem 0" center>
        <Heading
          color={color.blue600}
          as="h3"
          maxWidthSm="25ch"
          margin="0 0 2rem"
          marginSm="0 auto 2rem"
        >
          Trusted by over 50,000 people across the world
        </Heading>
        <LogoWrapper>
          <Image
            style={{
              padding: "0 1rem",
              filter:
                "grayscale(100%) sepia(25%) saturate(2585%) hue-rotate(161deg) brightness(95%) contrast(103%)",
            }}
            objectFit="scale-down"
            file={images["01c7bbb0-277d-499b-be8a-46c14c00f95f"]}
          />
        </LogoWrapper>
      </Div>
      <KlipsFAQ />
    </Div>
  )
}

KlipsDetails.propTypes = {
  handleClick: PropTypes.func.isRequired,
  microsite: PropTypes.bool,
}
