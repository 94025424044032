import styled from "styled-components"

export const FormWrapper = styled.div`
  .hs_email.hs-email.hs-fieldtype-text.field.hs-form-field {
    margin-bottom: 2rem;
  }
  
  label[placeholder="Enter your Email"] {
    display: block;
    margin-bottom: 0.5rem;
  }

  label#label-kf_top_ups_requested-59f2d53d-36f6-4fea-b0f9-25790c85046a {
    display: block;
    margin-bottom: 0.5rem;
  }

  .hs-city {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-flow: row;

    .input {
      grid-column: 1/-1;
    }
  }

  .hs-field-desc {
    font-size: 0.8rem;
    text-align: right;
    display: flex;
    align-self: flex-end;
    margin-bottom: 0.2rem;
  }

  ul.inputs-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  form.hs-form li {
    margin-bottom: 0.5rem;
  }
  
  form.hs-form li label {
    color: #333;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  input[type="checkbox"].hs-input {
    width: auto !important;
    margin-right: 0.5rem;
    min-height: auto;
  }

  .hs-form-checkbox {
    margin-bottom: 0 !important;
  }

  button {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    span {
      color: #21264f;
      font-size: 2rem;
    }
  }

  .form-columns-1, .form-columns-2, .hs-form-field {
    margin-bottom: 0 !important;
  }

  .input { 
    margin-right: 0 !important;
  }

  select, input {
    height: auto !important;
  }

  label {
    margin-top: 0.5rem;
  }
`