import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Span from "@components/elements/span"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import Grid from "@components/elements/grid"

export const PowerMetricsFeatureCard = ({
  title,
  description,
  appendix,
  cost,
  smallCost,
  link,
}) => {
  const { color, shadow } = useTheme()
  return (
    <Flex background="white" overflow="hidden" borderRadius="1rem" boxShadow={shadow.soft}>
      <Flex gap="1rem" padding="2rem">
        <Heading as="h4">{title}</Heading>
        <Paragraph fontSize="1.1rem" lineHeight={1.7}>
          {description}
        </Paragraph>
        {appendix && (
          <Paragraph fontSize={"1rem"} color={color.indigo500} lineHeight={1.7}>
            {appendix}
          </Paragraph>
        )}
      </Flex>
      <Grid
        margin="auto 0 0"
        padding="1rem 2rem"
        background={color.indigo100}
        justifyContent="space-between"
        alignItems="center"
        columns="auto auto"
        gap="0.5rem"        
      >
        {cost && (
          <Paragraph fontSize={smallCost && "1.1rem"} lineHeight="1.4" fontWeight={700}>
            {cost}&nbsp;
            <Span fontSize="1rem" opacity={0.3}>
              billed annually*
            </Span>
          </Paragraph>
        )}
        {link}
      </Grid>
    </Flex>
  )
}

PowerMetricsFeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  appendix: PropTypes.string,
  cost: PropTypes.string,
  smallCost: PropTypes.bool,
  link: PropTypes.node.isRequired,
}
