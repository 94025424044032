import React from "react"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import { PowerMetricsFeatureCard } from "./feature-card"
import PropTypes from "prop-types"

export const PowerMetricsAddOns = ({ handleClick }) => {
  return (
    <Div id="add-ons" container margin="0 auto 6rem">
      <Heading as="h2" margin="4rem 0 1rem" center>
        Add-Ons
      </Heading>
      <Paragraph margin="0 0 2rem" center>
        Customize your plan
      </Paragraph>
      <Grid columns="1fr 1fr" columnsMd="1fr" gap="2rem">
        <PowerMetricsFeatureCard
          title="Data Warehouse Integrations"
          description="Query data directly from Snowflake, BigQuery, Databricks, Redshift, PostgreSQL and MariaDB."
          appendix="Your data stays secure in the warehouse while business users access a centralized metric catalog for self-serve analysis and dashboard creation."
          cost="$325/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="PowerMetrics AI"
          description="Have a conversation with your business data."
          appendix="Harness the power of generative AI to help business users quickly and easily analyze and visualize data using natural language."
          cost="$270/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="Performance Booster"
          description="Load more data into PowerMetrics and refresh your metric data more often."
          appendix="Increase the hourly ingestion limit beyond the fair use limit included in your plan and get more frequent data refreshes. Note that the fair use limit does not apply to Data Warehouse and Semantic Layer Integrations."
          cost="$220/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="SSL Domain Alias"
          description="Use your own domain name."
          appendix="Let your users sign in through a domain you specify, instead of through app.klipfolio.com."
          cost="$99/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="Single Sign-On"
          description="Single Sign-On to the PowerMetrics app."
          appendix="Enables Single Sign-On for your PowerMetrics app with options to integrate via SAML or a custom approach."
          cost="$69/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="Date Range Window +"
          description="Add an extra year to your date range window."
          appendix="The date range window determines how much data you can see at a time in your metrics and dashboards. This limit does not apply to Data Warehouse and Semantic Layer Integrations."
          cost="$39/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="Additional Users"
          description="Add users so more of your teammates can interact with metrics and dashboards."
          appendix="A user is a named individual who can log into PowerMetrics with a unique email address."
          cost="$27.5/user/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />

        <PowerMetricsFeatureCard
          title="Additional Published Views"
          description="Share and present more dashboard views."
          appendix="Share dashboard views internally and externally (no Klipfolio account required) and present dashboards on every device, from large TVs, to desktop computers and mobile devices."
          cost="$27.5/view/mo"
          link={
            <Anchor onClick={handleClick} fontSize="1.25rem">
              Contact Us
            </Anchor>
          }
        />
      </Grid>
      <Div textContainer>
        <Paragraph
          center
          margin="2rem 0 1rem"
          fontSize="0.9rem"
          fontWeight={600}
        >
          *Prices shown in USD/mo, based on an annual Professional subscription.
          Please note, add-on prices may differ for monthly plans.&nbsp;
          <Anchor onClick={handleClick}>Contact us</Anchor> for more
          information.
        </Paragraph>
      </Div>
    </Div>
  )
}

PowerMetricsAddOns.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
