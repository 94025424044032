import { graphql, useStaticQuery } from "gatsby"

export const useNeutralGradient = () => {
  const { neutralGradient } = useStaticQuery(
    graphql`
      query UseNeutralGradient {
        neutralGradient: directusFiles(
          directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
        ) {
          id
          cdn
          title
        }
      }
    `
  )

  const gradient = neutralGradient
  return { gradient }
}
