import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import Grid from "@components/elements/grid"
import List from "@components/elements/list"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import { Tooltip } from "@components/common/tooltip"
import Span from "@components/elements/span"
import Flex from "@components/elements/flex"
import useDebouncedState from "@hooks/use-debounced-state"

const StyledList = styled(List)`
  margin-bottom: 0;
  li {
    display: flex;
    align-items: center;
    height: 70px;
    line-height: 1.25;
    li:not(:last-child) {
      border-bottom: 1px solid rgba(33, 38, 79, 0.2) !important;
    }
  }
`

const ContactButton = ({ klips, children, ...rest }) => {
  const { color } = useTheme()

  return (
    <Div margin="auto 0 0">
      <Div margin="2rem 0" padding="0.75rem 2rem">
        <Button
          background="none"
          arrow
          color={klips ? color.blue600 : color.indigo400}
          notice={false}
          padding="0.75rem 0"
          style={{
            textAlign: "center",
            width: "100%",
            border: `2px solid ${klips ? color.blue600 : color.indigo400}`,
            lineHeight: 1,
          }}
          data-cta-location="pricing-table"
          {...rest}
        >
          {children}
        </Button>
      </Div>
    </Div>
  )
}

ContactButton.propTypes = {
  klips: PropTypes.bool,
  children: PropTypes.node,
}

const EntryTitle = ({
  entry,
  monthly,
  title,
  klips,
  index,
  isHovering,
  hover,
}) => {
  let value = ""
  if (entry?.monthly && entry?.annual) {
    value = monthly ? entry.monthly : entry.annual
  } else {
    value = entry.value
  }

  return title ? (
    <Flex display="inline-flex" gap="0.5rem">
      <Flex flexFlow="row" alignItems="center" gap="0.5rem">
        {title}
        {hover && (
          <Tooltip
            size="16px"
            klips={klips}
            index={index}
            isHovering={isHovering}
          >
            {hover}
          </Tooltip>
        )}
        <br />
      </Flex>
      <Span color="inherit" fontWeight={500}>
        {value}
      </Span>
    </Flex>
  ) : (
    value
  )
}

EntryTitle.propTypes = {
  entry: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  monthly: PropTypes.bool,
  title: PropTypes.string,
  klips: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isHovering: PropTypes.bool,
  hover: PropTypes.string,
}

const PricingItem = ({
  index,
  entry,
  info,
  monthly,
  titles,
  klips,
  mobile,
}) => {
  const [isHovering, setIsHovering] = useDebouncedState(false, 200)
  const { color } = useTheme()

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [setIsHovering])

  const handleMouseOut = useCallback(() => {
    setIsHovering(false)
  }, [setIsHovering])

  const title = titles?.[index].value
  const hover = titles?.[index].hover || entry?.hover

  return (
    !(entry.hideMobile && titles) && (
      <li
        style={{
          color: info && color.indigo600,
          fontWeight: "700",
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
        onMouseEnter={hover && handleMouseOver}
        onMouseLeave={hover && handleMouseOut}
      >
        <span style={{ display: "inline" }}>
          <EntryTitle
            index={index}
            entry={entry}
            monthly={monthly}
            title={title}
            klips={klips}
            isHovering={isHovering}
            hover={hover}
          />
          {hover && !mobile && (
            <Tooltip
              style={{ marginLeft: "0.5rem", top: "2px"}}
              size="16px"
              klips={klips}
              index={index}
              isHovering={isHovering}
            >
              {hover}
            </Tooltip>
          )}
        </span>
      </li>
    )
  )
}

PricingItem.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entry: PropTypes.object,
  info: PropTypes.bool,
  monthly: PropTypes.bool,
  titles: PropTypes.array,
  klips: PropTypes.bool,
  mobile: PropTypes.bool,
}

export const PricingColumn = ({
  klips,
  list,
  info,
  monthly,
  titles,
  first,
  last,
  mobile,
}) => {
  let padding = "0"
  if (first) {
    padding = "0 0 0 2rem"
  }
  if (last) {
    padding = "0 2rem 0 0"
  }
  if (mobile) {
    padding = "0 1.5rem 1.5rem"
  }

  return (
    <Grid
      rows="auto 1fr"
      transition="box-shadow .33s ease"
      background="white"
      position="relative"
      padding={padding}
    >
      <StyledList
        fontSize="1rem"
        gap="0"
        itemPadding="1rem 1.25rem 1.25rem 0"
        borderBottom="1px solid #ddd"
        margin="0 0 2rem"
        listStyle="none"
        klips={klips}
      >
        {list.map((entry, i) => {
          return (
            <PricingItem
              key={`pricing-item-${i}`}
              index={i}
              entry={entry}
              info={info}
              monthly={monthly}
              titles={titles}
              klips={klips}
              mobile={mobile}
            />
          )
        })}
      </StyledList>
    </Grid>
  )
}

PricingColumn.propTypes = {
  klips: PropTypes.bool,
  button: PropTypes.bool,
  list: PropTypes.array,
  info: PropTypes.bool,
  contact: PropTypes.bool,
  handleYearlyClick: PropTypes.func,
  monthly: PropTypes.bool,
  titles: PropTypes.array,
  isAvailableMonthly: PropTypes.bool,
  onClick: PropTypes.func,
  first: PropTypes.bool,
  last: PropTypes.bool,
  mobile: PropTypes.bool,
}
