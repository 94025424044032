import { motion } from "framer-motion"
import Div from "./div"
import Flex from "./flex"
import Grid from "./grid"
import Heading from "./heading"
import Paragraph from "./paragraph"

export const MotionDiv = motion(Div)
export const MotionFlex = motion(Flex)
export const MotionGrid = motion(Grid)
export const MotionParagraph = motion(Paragraph)
export const MotionHeading = motion(Heading)
