import React from "react"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"
import { KlipsFeatureCard } from "./feature-card"
import { KlipsServices } from "./services"

export const KlipsAddOns = ({ handleClick }) => {
  const { color, shadow } = useTheme()
  return (
    <>
      <Div id="add-ons" container margin="0 auto 6rem">
        <Heading as="h2" color={color.blue600} margin="4rem 0 1rem" center>
          Add-Ons & Top-Ups
        </Heading>
        <Paragraph color={color.blue700} margin="0 0 2rem" center>
          Customize your plan
        </Paragraph>
        <Div
          boxShadow={shadow.soft}
          background="white"
          padding="1.5rem"
          borderRadius="1rem"
          margin="0 0 2rem"
        >
          <Paragraph color={color.blue600}>
            Interested in a custom plan?&nbsp;
            <Anchor color={color.blue400} onClick={handleClick}>
              Contact us
            </Anchor>
            . Our team is ready to help.
          </Paragraph>
        </Div>
        <Grid columns="1fr 1fr" columnsMd="1fr" gap="2rem">
          <KlipsFeatureCard
            title="Dashboard Top-Ups"
            description="Add more dashboards to effectively communicate your data story."
            appendix="Your data stays secure in the warehouse while business users access a centralized metric catalog for self-serve analysis and dashboard creation."
            cost="$8/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Near Real Time Refresh"
            description="Puts your data sources into a dedicated queue for up-to-the-minute data. Enables scheduled warm-up for offline refresh, ensuring data is current on first login."
            appendix="Shortens dashboard load times and optimizes handling of dynamic data sources."
            cost="$139/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Performance Tuning"
            description="Our experts analyze your unique usage of Klipfolio to ensure your formulas and performance settings are optimal."
            appendix="Helpful for environments where multiple users are connecting to data assets at the same time."
            cost="$220/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Large Data Processing"
            description="Ability to work with larger data sets (up to 15MB or 25MB) depending on your needs."
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Warm Up Refresh"
            description="Enables you to specify a time once a day to refresh all data sources in your account so they’re ready to go when users first log in."
            cost="$39/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Branding+"
            description="Promote and maintain your brand by putting your company name front and centre."
            appendix="Change key terminology (like “Klip” and “Dashboard”) throughout the product. Put your logo on the header and footer of every page and every report."
            cost="$199/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="SSL Domain Alias"
            description="Use your own WWW domain name (URL)."
            cost="$69/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Custom Theme"
            description="Upload custom CSS and JavaScript to dramatically overhaul Klipfolio’s look and feel."
            cost="$69/mo"
            link={
              <Anchor
                color={color.blue500}
                link={"/klips/custom-theme"}
                fontSize="1.25rem"
              >
                Learn More
              </Anchor>
            }
          />
        </Grid>
      </Div>

      <KlipsServices />

      <Div id="add-ons-2" container margin="0 auto 6rem">
        <Heading color={color.blue600} as="h2" margin="4rem 0 2rem" center>
          Support & Scaling
        </Heading>
        <Grid columns="1fr 1fr" columnsMd="1fr" gap="2rem">
          <KlipsFeatureCard
            title="Priority Support"
            description="Ensures your questions jump to the top of our Support queue. Access to a Klipfolio expert during business hours (9AM-5PM EST)."
            cost="$49/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Dedicated Server"
            description="Your Klipfolio account is hosted on a dedicated server for maximum performance."
            appendix="Using a private server dramatically increases compute resources for calculating formulas and fetching data."
            cost="$699/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Dedicated Server+"
            description="Your Klipfolio account is hosted on a larger, dedicated virtual machine, ensuring you achieve the best possible performance."
            appendix="Also includes account performance audits and tune-ups performed quarterly by Klipfolio experts."
            cost="$1,199/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />

          <KlipsFeatureCard
            title="Single Sign-On"
            description="Enables single sign-on for your Klipfolio app with options to integrate via SAML or a custom approach."
            cost="$49/mo"
            link={
              <Anchor
                color={color.blue500}
                onClick={handleClick}
                fontSize="1.25rem"
              >
                Contact Us
              </Anchor>
            }
          />
        </Grid>
        <Div textContainer>
          <Paragraph
            center
            margin="2rem 0 1rem"
            fontSize="0.9rem"
            fontWeight={600}
          >
            *Prices shown in USD/mo, based on an annual Professional
            subscription. Please note, add-on prices may differ for monthly
            plans.&nbsp;
            <Anchor color={color.blue500} onClick={handleClick}>
              Contact us
            </Anchor>{" "}
            for more information.
          </Paragraph>
        </Div>
      </Div>
    </>
  )
}

KlipsAddOns.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
