import React from "react"
import styled from "styled-components"
import { ReactComponent as CheckmarkIcon } from "@images/icons/checkmark-circle.svg"
import { ReactComponent as NoCheckmarkIcon } from "@images/icons/no-checkmark-circle.svg"

const AddMore = styled.span`
  font-size: 0.85rem;
  font-weight: 600;
  color: #b7b9c8;
  margin-left: 0.25rem;
`

export const PM_PRICING_COMPARISON = [
  { value: "Metrics" },
  { value: "Users" },
  {
    value: "Data Feeds Integrations",
    hover:
      "Connect, model, and deliver data to metrics on a schedule using cloud services, REST, spreadsheets and more.",
  },
  {
    value: "Data Warehouse Integrations",
    hover:
      "Query data directly from Snowflake, BigQuery, Databricks, Redshift, PostgreSQL and MariaDB.",
  },
  {
    value: "Semantic Layer Integrations",
    hover:
      "Import metrics from the dbt Semantic Layer and Cube.",
  },
  {
    value: "PowerMetrics Query Language",
    hover:
      "PowerMetrics Query Language (PMQL) exposes the metrics in PowerMetrics as tables, providing easy access to relevant data in external applications through an SQL-like interface.",
  },
  {
    value: "PowerMetrics AI",
    hover:
      "Have a conversation with your business data. Using natural language, PowerMetrics understands your intentions and uses generative AI to build the visualizations that answer your questions.",
  },
  { value: "Advanced Analysis" },
  { value: "Customize Dashboard Style" },
  { value: "Download PDF Reports" },
  { value: "Published Views" },
  { 
    value: "Live Embedding",
    hover: "With live embedding, you can display dynamic versions of PowerMetrics visualizations externally."
  },
  { value: "Priority Support" },
  { value: "Enterprise SSO" },
  { value: "Service Level Agreement" },
  { value: "Custom Service Agreement" },
  { value: "Vendor Assessment" },
  {
    value: "Private Tenancy",
    hover:
      "A private-tenant cloud runs on a dedicated infrastructure. Contact us to learn more.",
  },
]

export const PM_PRICING_HEADER_FREE = {
  heading: "Free",
  description:
    "30 day access to all premium features.",
}

export const PM_PRICING_ENTRIES_FREE = [
  { value: "20" },
  { value: "2" },
  { value: <CheckmarkIcon /> },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: "30-Day trial" },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const PM_PRICING_HEADER_STANDARD = {
  heading: "Professional",
  monthlyPrice: 60,
  annualPrice: 56,
}

export const PM_PRICING_ENTRIES_STANDARD = [
  { value: "Unlimited" },
  {
    annual: (
      <span key="std-user-desktop-annual">
        2 <AddMore>(Add more for $330/user/yr)</AddMore>
      </span>
    ),
    monthly: (
      <span key="std-user-desktop-monthly">
        2 <AddMore>(Add more for $30/user/mo)</AddMore>
      </span>
    ),
    mobile: true,
  },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon />  },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: "Available as add-on" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  {
    annual: (
      <span key="std-view-desktop-annual">
        5 <AddMore>(Add more for $330/view/yr)</AddMore>
      </span>
    ),
    monthly: (
      <span key="std-view-desktop-monthly">
        5 <AddMore>(Add more for $30/view/mo)</AddMore>
      </span>
    ),
    mobile: true,
  },
  { value: <NoCheckmarkIcon />, hideMobile: true  },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const PM_PRICING_HEADER_CUSTOM = {
  heading: "Business",
  monthlyDescription: "Annual subscriptions only.",
  description: "Customize your plan."
}

export const PM_PRICING_ENTRIES_CUSTOM = [
  { value: "Unlimited" },
  {
    annual: (
      <span key="custom-user-desktop-annual">
        10 <AddMore>(Add more for $220/user/yr)</AddMore>
      </span>
    ),
    monthly: (
      <span key="custom-user-desktop-monthly">
        10
      </span>
    ),
    mobile: true,
  },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: "Unlimited" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: "Contact Us" },
]
