import {useCallback, useState} from "react";
import debounce from "debounce";

export default (
	value,
	delay
) => {
	const [_value, _setValue] = useState(value);
	return [
		_value,
		useCallback(debounce(_setValue, delay), []),
		_setValue
	];
};