import React, { useState } from "react"
import { KlipsSelector, Selector } from "@components/pages/pricing/selector"
import PropTypes from "prop-types"
import { KlipsAgencyPricing } from "./agency"
import { KlipsBusinessPricing } from "./business"
import { AnimatePresence } from "framer-motion"

const PRICING_TYPES = {
  AGENCY: "agency",
  BUSINESS: "business",
}

export const KlipsPricing = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
  type = PRICING_TYPES.BUSINESS,
}) => {
  const [agency, setAgency] = useState(type === PRICING_TYPES.AGENCY)

  return (
    <>
      <Selector
        showOnMd
        klips
        monthly={monthly}
        handleMonthlyClick={handleMonthlyClick}
        handleYearlyClick={handleYearlyClick}
        style={{ margin: "0 auto 2rem", zIndex: 1, position: "relative" }}
      />
      <KlipsSelector
        agency={agency}
        setAgency={setAgency}
        style={{ position: "relative" }}
      />
      <AnimatePresence initial={false} mode="wait">
        {agency ? (
          <KlipsAgencyPricing
            key="klipsAgency"
            monthly={monthly}
            handleMonthlyClick={handleMonthlyClick}
            handleYearlyClick={handleYearlyClick}
          />
        ) : (
          <KlipsBusinessPricing
            key="klipsBusiness"
            monthly={monthly}
            handleMonthlyClick={handleMonthlyClick}
            handleYearlyClick={handleYearlyClick}
          />
        )}
      </AnimatePresence>
    </>
  )
}

KlipsPricing.propTypes = {
  images: PropTypes.object.isRequired,
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
  type: PropTypes.string,
}
