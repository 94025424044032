import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import media from "../../styles/media"
import Div from "@components/elements/div"
import { InformationIcon } from "@components/icons/common/information"
import useDebouncedState from "@hooks/use-debounced-state"

const StyledTooltip = styled.div`
  position: absolute;
  min-width: 300px;
  background-color: ${props =>
    props.klips ? props.theme.color.blue700 : props.theme.color.indigo700};
  color: #fff;
  border-radius: 4px;
  z-index: 1;
  top: 50%;
  left: calc(100% + 1rem);
  transform: translateY(-51%);
  padding: 1rem;
  line-height: 1.5;

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &::before {
    content: "";
    position: absolute;
    transform: translateY(-50%);
    border-width: 0.5rem;
    border-style: solid;

    ${media.md_up`
      top: 50%;
      left: -1rem;
      border-color: transparent ${props =>
        props.klips
          ? props.theme.color.blue700
          : props.theme.color.indigo700} transparent transparent;
    `}
  }

  ${media.md`
    ${props => props.mobileAlignment}
  `}
`

const mobileRight = css`
  top: unset;
  bottom: calc(100% + 1rem);
  left: unset;
  right: -1rem;
  transform: unset;

  &::before {
    bottom: -1.4rem;
    right: 1.1rem;
    border-color: ${props =>
        props.klips ? props.theme.color.blue700 : props.theme.color.indigo700}
      transparent transparent transparent;
  }
`
const mobileLeft = css`
  top: unset;
  bottom: calc(100% + 1rem);
  left: -1rem;
  right: unset;
  transform: unset;

  &::before {
    bottom: -1.4rem;
    left: 1.1rem;
    border-color: ${props =>
        props.klips ? props.theme.color.blue700 : props.theme.color.indigo700}
      transparent transparent transparent;
  }
`

const mobileCenter = css`
  top: unset;
  bottom: calc(100% + 1rem);
  left: 1.1rem;
  right: unset;
  transform: translateX(-50%);

  &::before {
    bottom: -1.4rem;
    right: 50%;
    border-color: ${props =>
        props.klips ? props.theme.color.blue700 : props.theme.color.indigo700}
      transparent transparent transparent;
  }
`

const MotionDiv = motion(StyledTooltip)

export const Tooltip = ({
  index,
  children,
  mobileAlignment = "left",
  klips,
  size = "20px",
  ...rest
}) => {
  const [isHovering, setIsHovering] = useDebouncedState(false, 200)

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [setIsHovering])

  const handleMouseOut = useCallback(() => {
    setIsHovering(false)
  }, [setIsHovering])

  let alignment = mobileRight
  if (mobileAlignment === "left") {
    alignment = mobileLeft
  } else if (mobileAlignment === "center") {
    alignment = mobileCenter
  }

  return (
    <Div
      position="relative"
      display="inline"
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      height={size}
      width={size}
      {...rest}
    >
      <InformationIcon
        style={{
          maxWidth: size,
          maxHeight: size,
          overflow: "visible",
        }}
      />
      <AnimatePresence>
        {isHovering && (
          <MotionDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            key={`hover-bubble-${index}`}
            mobileAlignment={alignment}
            klips={klips}
          >
            <p>{children}</p>
          </MotionDiv>
        )}
      </AnimatePresence>
    </Div>
  )
}

Tooltip.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  mobileAlignment: PropTypes.oneOf(["left", "right", "center"]),
  klips: PropTypes.bool,
  size: PropTypes.string,
}
