import React from "react"
import Heading from "../heading"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

export const MotionHeading = motion(Heading)

export const FadeHeading = ({ children, ...rest }) => {
  return (
    <MotionHeading
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      {...rest}
    >
      {children}
    </MotionHeading>
  )
}

FadeHeading.propTypes = {
  children: PropTypes.node,
}
